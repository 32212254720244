<template>
  <v-container>
    <v-sheet class="pa-4" elevation="2" id="page" style="font-family:Deng">
      <v-row class="mt-4">
        <v-col cols="12">
          <p
            class="text-h2 text-center"
            style="font-size:60px; text-align:center"
          >
            {{ company.name_cn }}
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="grey lighten-4">
        <v-col cols="12" md="6">
          <span class="text-h7">联系电话：{{ company.phone }}</span>
        </v-col>
        <v-col cols="12" md="6">
          <span class="text-h7">
            联{{ "\u2002" }}系{{ "\u2002" }}人：{{ company.contact }}
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <span class="text-h7">
            地{{ "\u3000\u3000" }}址：{{ company.address_cn }}
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <span class="text-h7">
            邮{{ "\u3000\u3000" }}件：{{ company.email }}
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="12" class="mt-2">
          <p
            class="text-h3 text-center"
            style="font-size:48px; text-align:center"
          >
            采 购 订 单
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="suppliers.length > 0">
        <v-col cols="12" md="9" class="order-1 order-md-1">
          <span class="text-h7">
            供{{ "\u2002" }}应{{ "\u2002" }}商：
            {{ suppliers[currentPage].name_cn }}
          </span>
        </v-col>
        <v-col cols="12" md="3" class="order-4 order-md-2">
          <span class="text-h7">
            订{{ "\u2002" }}单{{ "\u2002" }}号：{{ orderInfo.ordernum }}
          </span>
        </v-col>
        <v-col cols="12" md="9" class="order-2 order-md-3">
          <span class="text-h7">
            联{{ "\u2002" }}系{{ "\u2002" }}人：
            {{ suppliers[currentPage].contact }}
          </span>
        </v-col>
        <v-col cols="12" md="3" class="order-5 order-md-4">
          <span class="text-h7">订单日期：{{ orderInfo.order_date }}</span>
        </v-col>
        <v-col cols="12" md="9" class="order-3 order-md-5">
          <span class="text-h7">
            联系电话：{{ suppliers[currentPage].mobile_phone }}
          </span>
        </v-col>
        <v-col cols="12" md="3" class="order-6 order-md-6">
          <span class="text-h7">交货日期：{{ orderInfo.request_date }}</span>
        </v-col>
      </v-row>
      <v-divider class="mt-2"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="currentLines"
            :loading="loading"
            :items-per-page="50"
            hide-default-footer
          >
            <template v-slot:[`item.index`]="{ item }">
              <span>{{ currentLines.indexOf(item) + 1 }}</span>
            </template>
            <template v-slot:[`item.product_code`]="{ item }">
              <span>{{ getProductCode(item) }}</span>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span>{{ getDescription(item) }}</span>
            </template>
            <template v-slot:[`item.unit`]="">
              <span>件</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.price_supplier * item.pd_qty }}</span>
            </template>
            <template v-slot:[`body.append`]>
              <tr>
                <td align="right">
                  <b>总{{ "\u3000" }}计</b>
                </td>
                <td colspan="6"></td>
                <td align="right">
                  <b>{{ totalAmount }}</b>
                </td>
              </tr>
            </template>

            <template v-slot:top>
              <v-dialog v-model="dialogAdd" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">添加订单行</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            v-model="productKey"
                            label="请输入关键字"
                            required
                            prepend-icon="mdi-keyboard-outline"
                            append-outer-icon="mdi-magnify"
                            @click:append-outer="searchProducts"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-if="searchResult.length > 0"
                            v-model="newLine.pd_id"
                            :items="searchResult"
                            :item-text="getProductInfo"
                            item-value="id"
                            label="请选择需要添加的产品"
                            prepend-icon="mdi-cart-outline"
                            @change="getProducts"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-if="newLine.pd_id"
                            v-model="newLine.pd_qty"
                            label="请输入产品数量"
                            prepend-icon="mdi-calculator"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-if="newLine.pd_id"
                            v-model="newLine.supplier_id"
                            :items="newProducts"
                            :item-text="getSupplierInfo"
                            item-value="source_id"
                            label="请选择供应商"
                            prepend-icon="mdi-factory"
                            @change="setPrice"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-if="newLine.pd_id"
                            v-model="newLine.pd_code"
                            label="输入自定义产品编码"
                            prepend-icon="mdi-cash-100"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-if="newLine.pd_id"
                            v-model="newLine.price_supplier"
                            label="按需修改默认价格"
                            prepend-icon="mdi-cash-100"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-if="newLine.pd_id"
                            v-model="newLine.pd_desc"
                            label="输入自定义产品描述"
                            auto-grow
                            rows="1"
                            prepend-icon="mdi-note-text-outline"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="addLine">添加</v-btn>
                    <v-btn @click="dialogAdd = false">关闭</v-btn>
                  </v-card-actions>

                  <v-overlay :absolute="true" :value="alert">
                    <v-card color="orange darken-4">
                      <v-card-title>失败</v-card-title>
                      <v-card-text>
                        {{ message }}
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange" @click="alert = false">
                          关闭
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-overlay>
                  <v-overlay :absolute="true" :value="success">
                    <v-card color="light-green darken-4">
                      <v-card-title>成功</v-card-title>
                      <v-card-text>
                        {{ message }}
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="success = false">
                          关闭
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-overlay>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDel" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">删除订单行</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row no-gutters>
                        <v-select
                          v-model="selectedLines"
                          :items="currentLines"
                          :item-text="getLineInfo"
                          item-value="id"
                          multiple
                          chips
                          label="请选择需要删除的订单行"
                          prepend-icon="mdi-checkbox-multiple-marked-outline"
                        ></v-select>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="delLines">删除</v-btn>
                    <v-btn @click="dialogDel = false">关闭</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="pt-2">
        <v-col cols="12">
          <span class="text-h7">
            总计大写：<b>{{ convertCurrency(totalAmount) }}</b>
          </span>
        </v-col>
        <v-col cols="12">
          <span class="text-h7">付款方式：</span>
        </v-col>
        <v-col cols="12">
          <span class="text-h7">交货地点：</span>
        </v-col>
        <v-col cols="12">
          <span class="text-h7">备{{ "\u3000\u3000" }}注：</span>
        </v-col>
        <v-col cols="12">
          <span class="text-h7">
            品质保证：所送产品必须完全符合我公司要求的各项指标，质量不符则退货。
          </span>
        </v-col>
        <v-col cols="9">
          <p
            class="text-h6 text-right"
            style="font-size:20px; text-align:right"
          >
            供应商签名：
          </p>
        </v-col>
        <v-col cols="9">
          <p
            class="text-h6 text-right"
            style="font-size:20px; text-align:right"
          >
            日{{ "\u3000\u3000" }}期：
          </p>
        </v-col>
      </v-row>
    </v-sheet>
    <v-row class="mt-4">
      <v-btn class="mx-4 mb-4" @click="openAdd">
        添加订单行<v-icon>mdi-plus-thick</v-icon>
      </v-btn>
      <v-btn class="mr-4 mb-4" @click="openDel">
        删除订单行<v-icon>mdi-minus-thick</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mr-4 mb-4" @click="exportPDF">
        导出PDF<v-icon>mdi-file-pdf-outline</v-icon>
      </v-btn>
      <v-btn class="mr-4 mb-4" @click="exportPDF1">
        导出PDF(PIC)<v-icon>mdi-file-pdf-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-4 mb-4"
        v-if="supplierIds.length > 1"
        :disabled="previousDisabled"
        @click="previousPage"
      >
        上一页<v-icon>mdi-page-previous-outline</v-icon>
      </v-btn>
      <v-btn
        class="mr-4 mb-4"
        v-if="supplierIds.length > 1"
        :disabled="nextDisabled"
        @click="nextPage"
      >
        下一页<v-icon>mdi-page-next-outline</v-icon>
      </v-btn>
    </v-row>
    <v-overlay :value="progress">
      <v-progress-circular
        :size="80"
        :width="8"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { baseUrl } from "../env";
import authHeader from "../services/auth-header";
import html2Canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  data: () => ({
    loading: false,
    dialogAdd: false,
    dialogDel: false,
    previousDisabled: true,
    nextDisabled: false,
    orderId: null,
    currentId: null,
    currentPage: 0,
    supplierIds: [],
    orderInfo: [],
    orderLines: [],
    currentLines: [],
    selectedLines: null,
    productKey: null,
    searchResult: [],
    newProducts: [],
    newLine: {
      order_id: null,
      pd_id: null,
      pd_code: null,
      pd_desc: null,
      pd_qty: 1,
      price_supplier: null,
      supplier_id: null
    },
    company: [],
    suppliers: [],
    headers: [
      {
        text: "项次",
        value: "index",
        sortable: false,
        align: "end",
        width: "80px"
      },
      { text: "编码", value: "product_code", sortable: false },
      { text: "简描", value: "customer_code", width: "150px", sortable: false },
      { text: "产品描述", value: "description", sortable: false },
      {
        text: "单价（元）",
        value: "price_supplier",
        sortable: false,
        align: "end",
        width: "120px"
      },
      {
        text: "数量",
        value: "pd_qty",
        sortable: false,
        align: "end",
        width: "100px"
      },
      { text: "单位", value: "unit", sortable: false, width: "80px" },
      {
        text: "合计",
        value: "amount",
        sortable: false,
        align: "end",
        width: "120px"
      }
    ],
    alert: false,
    success: false,
    message: "",
    progress: false
  }),

  computed: {
    totalAmount() {
      var result = 0;
      for (var i = 0; i < this.currentLines.length; i++) {
        result +=
          this.currentLines[i].price_supplier * this.currentLines[i].pd_qty;
      }
      return result;
    }
  },

  created() {
    this.orderId = this.$route.params.id;
    this.getOrderInfo();
    this.getOrderLines();
    this.getCompany();
  },

  methods: {
    getOrderInfo() {
      fetch(baseUrl + "/api/order/" + this.orderId, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          if (Response.ok) {
            return Response.json();
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .then(jsonData => {
          this.orderInfo = jsonData[0];
        })
        .catch(() => {
          this.$route.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },

    getOrderLines() {
      fetch(baseUrl + "/api/orderlines/" + this.orderId, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.orderLines = jsonData;
          let arr = [];
          for (var i in this.orderLines) {
            arr.push(this.orderLines[i].supplier_id);
          }
          this.supplierIds = this.unique(arr);
          this.suppliers = [];
          for (i in this.supplierIds) {
            this.currentId = this.supplierIds[i];
            this.getSupplier();
          }
          if (this.supplierIds.length > 0) {
            this.currentId = this.supplierIds[0];
            this.currentLines = this.orderLines.filter(item => {
              return item.supplier_id == this.currentId;
            });
          }
        });
    },

    getCompany() {
      fetch(baseUrl + "/api/companys/FTMOUNT", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.company = jsonData[0];
        });
    },

    getSupplier() {
      fetch(baseUrl + "/api/company/" + this.currentId, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.suppliers.push(jsonData[0]);
        });
    },

    getProducts() {
      fetch(baseUrl + "/api/product/" + this.newLine.pd_id, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.newProducts = jsonData;
          this.newLine.supplier_id = this.newProducts[0].source_id;
          this.newLine.price_supplier = this.newProducts[0].price;
        });
    },

    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage -= 1;
        this.nextDisabled = false;
        if (this.currentPage == 0) {
          this.previousDisabled = true;
        }
        this.currentId = this.supplierIds[this.currentPage];
        this.currentLines = this.orderLines.filter(item => {
          return item.supplier_id == this.currentId;
        });
      }
    },

    nextPage() {
      if (this.currentPage < this.suppliers.length - 1) {
        this.currentPage += 1;
        this.previousDisabled = false;
        if (this.currentPage == this.suppliers.length - 1) {
          this.nextDisabled = true;
        }
        this.currentId = this.supplierIds[this.currentPage];
        this.currentLines = this.orderLines.filter(item => {
          return item.supplier_id == this.currentId;
        });
      }
    },

    openAdd() {
      this.newLine.order_id = this.orderId;
      this.dialogAdd = true;
    },

    searchProducts() {
      fetch(baseUrl + "/api/products/" + this.productKey, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.searchResult = jsonData.rows;
          this.newLine.pd_id = null;
          this.newLine.pd_qty = 1;
          this.newLine.price_supplier = null;
          this.newLine.supplier_id = null;
        });
    },

    setPrice(id) {
      for (var i = 0; i < this.newProducts.length; i++) {
        if (this.newProducts[i].source_id == id) {
          this.newLine.price_supplier = this.newProducts[i].price;
          break;
        }
      }
    },

    addLine() {
      if (this.newLine.pd_id === null) {
        this.message = "请选择产品！";
        this.alert = true;
        return;
      }
      this.message = "";
      fetch(baseUrl + "/api/orderline", {
        method: "post",
        headers: authHeader(),
        body: JSON.stringify(this.newLine)
      })
        .then(Response => {
          if (Response.ok) {
            this.success = true;
            this.message = "添加订单行成功！";
            this.getOrderLines();
            this.newLine.pd_id = null;
            this.newLine.pd_code = null;
            this.newLine.pd_desc = null;
            this.newLine.pd_qty = 1;
            this.newLine.price_supplier = null;
            this.newLine.supplier_id = null;
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .catch(() => {
          this.alert = true;
          this.message = "添加订单行失败！";
        });
    },

    openDel() {
      this.dialogDel = true;
    },

    delLines() {
      if (this.selectedLines.length > 0) {
        for (var i = 0; i < this.selectedLines.length; i++) {
          fetch(baseUrl + "/api/orderline/" + this.selectedLines[i], {
            method: "delete",
            headers: authHeader()
          }).then(function(Response) {
            console.log(Response);
          });
          for (var j = 0; j < this.orderLines.length; j++) {
            if (this.orderLines[j].id == this.selectedLines[i]) {
              this.orderLines.splice(j, 1);
              break;
            }
          }
          for (j = 0; j < this.currentLines.length; j++) {
            if (this.currentLines[j].id == this.selectedLines[i]) {
              this.currentLines.splice(j, 1);
              break;
            }
          }
        }
        this.selectedLines = null;
      }
    },

    getLineInfo(item) {
      return item.supplier_code + " / " + item.customer_code;
    },

    getProductInfo(item) {
      var productInfo =
        item.supplier_code + " / " + item.customer_code + " / " + item.code;
      return productInfo;
    },

    getSupplierInfo(item) {
      return item.source + "(" + item.target + ")";
    },

    getDescription(item) {
      var Description = "";
      if (item.pd_desc === null || item.pd_desc === "") {
        if (item.desc_cn === null || item.desc_cn === "") {
          Description = item.desc_en;
        } else {
          Description = item.desc_cn;
        }
      } else {
        Description = item.pd_desc;
      }
      return Description;
    },

    getProductCode(item) {
      var ProductCode = "";
      if (item.pd_code === null || item.pd_code === "") {
        if (item.supplier_code === null || item.supplier_code === "") {
          if (item.code === null || item.code === "") {
            ProductCode = item.customer_code;
          } else {
            ProductCode = item.code;
          }
        } else {
          ProductCode = item.supplier_code;
        }
      } else {
        ProductCode = item.pd_code;
      }
      return ProductCode;
    },

    async getFont(name) {
      const response = await fetch(baseUrl + "/api/fonts/" + name, {
        method: "get",
        headers: authHeader()
      });
      const fontstring = await response.text();
      return fontstring;
    },

    exportPDF() {
      var index = this.supplierIds.indexOf(this.currentId);
      var postfix = this.suppliers[index].short_name;
      var filename = "采购订单" + this.orderInfo.ordernum + postfix + ".pdf";
      const doc = new jsPDF("p", "pt", "a4");
      const page = document.getElementById("page");
      let scale = 575.28 / page.offsetWidth;
      this.progress = true;
      this.getFont("Deng").then(font => {
        doc.addFileToVFS("Deng.ttf", font);
        doc.addFont("Deng.ttf", "deng", "normal");
        doc.setFont("deng", "normal");
        doc.html(page, {
          callback: function(doc) {
            doc.save(filename);
          },
          html2canvas: {
            scale: scale
          },
          x: 10,
          y: 10
        });
        this.progress = false;
      });
    },

    exportPDF1() {
      // 导出之前先将滚动条置顶,不然会出现数据不全的现象
      window.pageYOffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      var index = this.supplierIds.indexOf(this.currentId);
      var postfix = this.suppliers[index].short_name;
      var filename = "采购订单" + this.orderInfo.ordernum + postfix + ".pdf";

      var element = document.getElementById("page");
      var contentWidth = element.clientWidth;
      var contentHeight = element.clientHeight;
      var canvas = document.createElement("canvas");
      var scale = 2;
      canvas.width = contentWidth * scale;
      canvas.height = contentHeight * scale;
      canvas.getContext("2d").scale(scale, scale);

      html2Canvas(document.querySelector("#page"), {
        allowTaint: true,
        useCORS: true,
        scale: 1,
        canvas: canvas,
        width: contentWidth,
        height: contentHeight
      }).then(function(canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 585.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;

        let pageData = new Image();
        //设置图片跨域访问
        pageData.setAttribute("crossOrigin", "Anonymous");

        setTimeout(() => {
          pageData = canvas.toDataURL("image/jpeg", 1.0);

          let PDF = new jsPDF("", "pt", "a4");
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              if (leftHeight > 0) {
                PDF.addPage();
              }
            }
          }
          PDF.save(filename);
        }, 1000);
      });
    },

    unique(arr) {
      return Array.from(new Set(arr));
    },

    convertCurrency(money) {
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "元";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (i = 0; i < decLen; i++) {
          n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }
      return chineseStr;
    }
  }
};
</script>
